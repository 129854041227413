@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

input,
textarea {
  box-sizing: border-box;
}

.container {
  flex-direction: column;

  max-width: 1200px;
  padding: 0 60px;
}

.content {
  display: flex;
  align-items: center;

  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.left-side {
  width: 20px;
  height: 40%;
  position: relative;
  display: flex;
}

.right-side {
  width: 25%;
  margin-left: 75px;
}

.details {
  margin-bottom: 15px;
  text-align: center;
}

.details i {
  font-size: 25px;
  color: #5214ff;
  margin-bottom: 10px;
}

.topic {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 25px;
}

.text-one,
.text-two {
  font-size: 15px;
  color: #000000;
}

.topic-text {
  font-size: 30px;
  margin-top: 30px;
  font-weight: 400;
  color: #0b1099;
  text-align: center;
  left: 50%;
  right: 50%;
}
.topic-text p {
  color: #000000;
}

.right-side p {
  margin-bottom: 50px;
}

.input-box {
  height: 40px;
  margin-bottom: 40px;
  background-color: #000000;
}

.input-box input,
.input-box textarea {
  height: 100%;
  width: 100%;
  border: 10px;
  border-radius: 10px;
  background: #5f5f5f;
  padding: 0 10px;
}

.input-box label {
  font-size: 12px;
  color: #555559;
  padding-left: 5px;
}

.input-box textarea {
  resize: none;
  padding: 50px;
  font-family: 'Roboto', sans-serif;
}

.message-box {
  min-height: 110px;
}

.button {
  display: inline-block;
}

.input__data-check {
  margin-top: 25px;
}

.button input[type='submit'] {
  color: #ffffff;
  font-size: 20px;
  background: #000000;
  outline: none;
  border: none;
  padding: 10px 20px;
  border-radius: 7px;
  transition: 0.2s;
}

.button input[type='submit']:hover {
  background: rgb(0, 0, 0);
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .header strong {
    font-size: 20px;
    font-weight: 400;
  }
  .container {
    padding: 0 20px;
  }
  .title-2 {
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
  }
  .intro__description {
    font-size: 12px;
    font-weight: 500;
  }

  .content-list__item {
    font-size: 14px;

    font-weight: 300;
  }
}

/* anti-doping */
.input-checkbox__row {
  height: 3.5em;
  margin-bottom: 0.5rem;
  color: black;
}

.privacy-policy-terms,
.contact-form,
.products {
  padding-top: 0;
}

.modal__h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
  font-weight: 600;

  font-family: 'Montserrat', sans-serif;
}

.modal__h1-subtitle {
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 30px;

  font-family: 'Montserrat', sans-serif;
}

.modal__p {
  font-weight: 500;
  font-size: 15px;

  font-family: 'Montserrat', sans-serif;
}
.modal__list {
  margin-top: 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}
.modal__p.-mod {
  font-size: 20px;

  margin-top: 30px;

  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 2.8;
  margin-bottom: 10px;
}

.intro__description {
  background: #f5f9ff;
  padding: 20px;
  border-radius: 3px;
  text-align: justify;
  color: #000000;
  margin-bottom: 2rem;
}
.intro__description .divider {
  max-width: 5rem;
  height: 2px;
  margin-top: 80px;
}

.logo-panda {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.logo-panda img {
  width: 170px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .modal__h1 {
    font-size: 22px;
  }
  .modal__h1-subtitle {
    font-size: 18px;
    white-space: nowrap;
  }
  .modal__list li {
    text-align: left;
    font-size: 18px;
  }
  .modal__p.-mod {
    font-size: 15px;
  }
}

/*contacts*/

@media screen and (min-width: 320px) and (max-width: 480px) {
  .topic-text {
    font-size: 23px;
  }
  .input-checkbox__row {
    font-size: 16px;
  }

  .checkbox-1 {
    border-width: 30px;
  }
}

#section__product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px 20px 0 20px;
  gap: 20px;
}
.section__product-title {
}

.title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;

  /* border-bottom: 1px solid var(--color-different) ; */
  /* padding: 0 30%; */
}
.section__product-title p {
  font-size: 23px;
  margin-top: 20px;
}

.section__product-title img {
  width: 500px;
  height: 300px;
  margin-top: 20px;
}

.image__product {
  margin: 30px 10px 20px 10px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    padding: 10 10%;
  }
  .section__product-title p {
    font-size: 17px;
    margin-top: 20px;
    text-align: left;
    list-style: inside;
  }
  .section__product-title img {
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }
}
